'use client'
import { Drawer, DrawerContent, DrawerFooter, DrawerTrigger } from './ui/drawer'
import React, { SetStateAction } from 'react'
import Link from 'next/link'
import { Button } from './ui/button'
import { Separator } from './ui'
import { AlignJustify, UserRoundCog, X } from 'lucide-react'
import { AuthFunction } from '@zent-auth/types/enums/AuthFunction'
import { signOut, useSession } from 'next-auth/react'
import { NEXT_PUBLIC_CONSOLE_CARE_URL, NEXT_PUBLIC_CONSOLE_OP_URL } from '@zent-auth/configs/ZENT_ENV'

const RootNavBar = ({ isOpen, setIsOpen }: { isOpen: boolean; setIsOpen: SetStateAction<any> }) => {
  const { data } = useSession()

  const doSignout = async () => {
    await signOut()
  }

  const categoryClassName = 'font-bold mb-1 rounded-md px-2 py-1'
  const itemClassName = 'block mb-1 px-3 py-1.5 hover:bg-neutral-100 dark:hover:bg-neutral-900'

  return (
    <Drawer direction="left" modal={false} open={isOpen}>
      <DrawerTrigger asChild>
        <Button variant="outline" size="icon" className="mx-2 my-4" onClick={() => setIsOpen(true)}>
          <AlignJustify className="w-4 h-4" />
        </Button>
      </DrawerTrigger>
      <DrawerContent className="h-[100vh] max-w-64 rounded-none">
        <div className="flex justify-between break-word">
          <div className="w-full">
            <div className="flex justify-end">
              <Button variant="ghost" size="icon" className="rounded-none" onClick={() => setIsOpen(false)}>
                <X className="w-4 h-4" />
              </Button>
            </div>
            <Separator orientation="horizontal" />
            {data && data.user && data.user?.functions.includes(AuthFunction.PAGE_REFUND) && (
              <>
                <div>
                  <div className={categoryClassName}>종소세 경정청구</div>
                  <div>
                    <Link className={itemClassName} href={`${NEXT_PUBLIC_CONSOLE_OP_URL}/refund`}>
                      분석상세
                    </Link>
                  </div>
                  <div>
                    <Link className={itemClassName} href={`${NEXT_PUBLIC_CONSOLE_OP_URL}/csform/small-business`}>
                      중특감 설문결과
                    </Link>
                  </div>
                  <div>
                    <Link className={itemClassName} href={`${NEXT_PUBLIC_CONSOLE_OP_URL}/csform/startup-business`}>
                      창중감 설문결과
                    </Link>
                  </div>
                </div>
                <Separator orientation="horizontal" />
              </>
            )}
            {data && data.user && data.user?.functions.includes(AuthFunction.PAGE_SALES) && (
              <>
                <div>
                  <div className={categoryClassName}>영업관리</div>
                  <div>
                    <Link className={itemClassName} href={`${NEXT_PUBLIC_CONSOLE_OP_URL}/sales/channels`}>
                      영업채널 관리
                    </Link>
                  </div>
                  <div>
                    <Link className={itemClassName} href={`${NEXT_PUBLIC_CONSOLE_OP_URL}/sales/salesman`}>
                      영업자 관리
                    </Link>
                  </div>
                  <div>
                    <Link className={itemClassName} href={`${NEXT_PUBLIC_CONSOLE_OP_URL}/sales`}>
                      영업 현황
                    </Link>
                  </div>
                  <div>
                    <Link className={itemClassName} href={`${NEXT_PUBLIC_CONSOLE_OP_URL}/sales/settlement`}>
                      정산관리
                    </Link>
                  </div>
                </div>
                <Separator orientation="horizontal" />
              </>
            )}
            {data && data.user && data.user?.functions.includes(AuthFunction.PAGE_ONEMIN) && (
              <>
                <div>
                  <div>
                    <Link className={itemClassName} href={`${NEXT_PUBLIC_CONSOLE_OP_URL}/1min`}>
                      1분 환급
                    </Link>
                  </div>
                </div>
                <Separator orientation="horizontal" />
              </>
            )}
            {data && data.user && data.user?.functions.includes(AuthFunction.PAGE_CORPTAX) && (
              <>
                <div>
                  <div>
                    <Link className={itemClassName} href={`${NEXT_PUBLIC_CONSOLE_OP_URL}/corptax`}>
                      법인세 경정청구
                    </Link>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <Separator orientation="horizontal" />
        {data && data.user && data.user?.functions.includes(AuthFunction.PAGE_CARE_SUBSCRIPTION) && (
          <>
            <div>
              <div className={categoryClassName}>비즈넵 케어</div>
              <div>
                <Link className={itemClassName} href={`${NEXT_PUBLIC_CONSOLE_CARE_URL}/subscription`}>
                  케어 구독현황
                </Link>
              </div>
            </div>
          </>
        )}
        <Separator orientation="horizontal" />
        <Separator orientation="horizontal" />
        <div>
          <Link className={itemClassName} href={`${NEXT_PUBLIC_CONSOLE_OP_URL}/externals`}>
            외부 링크
          </Link>
        </div>
        <Separator orientation="horizontal" />
        <div>
          <a className={itemClassName} href="https://bznav.com/" target="_blank">
            비즈넵(SME)
          </a>
        </div>
        <div>
          <a className={itemClassName} href="https://bznav.com/tax/refund" target="_blank">
            비즈넵 환급
          </a>
        </div>
        <div>
          <a className={itemClassName} href="https://care.bznav.com/" target="_blank">
            비즈넵 케어
          </a>
        </div>
        <div>
          <a className={itemClassName} href="https://help.bznav.com/hc/ko" target="_blank">
            비즈넵 고객센터(구 헬프센터)
          </a>
        </div>
        <div>
          <a className={itemClassName} href="https://1min.bznav.com/ " target="_blank">
            1분
          </a>
        </div>
        <Separator orientation="horizontal" />
        {data && data.user && data.user?.functions.includes('PAGE_ADMIN_USER') && (
          <div>
            <Link className={itemClassName} href={`${NEXT_PUBLIC_CONSOLE_OP_URL}/admin/users`}>
              <div className="flex items-center">
                <UserRoundCog className="w-4 h-4 mr-2" />
                콘솔 권한 관리
              </div>
            </Link>
          </div>
        )}

        <DrawerFooter>
          <Button variant="secondary" onClick={doSignout}>
            로그아웃
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
}
export default RootNavBar
