'use client'
import RootNavBar from './RootNavBar'
import React, { FC, PropsWithChildren, useEffect, useState } from 'react'
import { useSession } from 'next-auth/react'
import { usePathname } from 'next/navigation'
import { useCookies } from 'react-cookie'
import { AXIOS_INSTANCE } from '@zent-auth/lib/fetcher'
import ConsoleBreadcrumb from './ConsoleBreadcrumb'
import { clsx } from 'clsx'
import ModeToggle from './ModeToggle'
import RefreshMyInfoButton from './RefreshMyInfoButton'
import Buttons from './Buttons'

const RootWrapper: FC<PropsWithChildren> = ({ children }) => {
  const { data: session } = useSession()

  const [cookies, setCookie] = useCookies(['zent-console-menubar-open'])

  const pathname = usePathname()
  const isShowNavBar = !pathname.includes('/fileview') && pathname !== '/'
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>()

  const getMenuOpen = () => {
    setTimeout(() => {
      setIsMenuOpen(cookies['zent-console-menubar-open'] !== 'close')
    }, 50)
  }

  useEffect(() => {
    setCookie('zent-console-menubar-open', isMenuOpen ? 'open' : 'close')
  }, [isMenuOpen])

  useEffect(() => {
    getMenuOpen()
  }, [])

  if (!session) {
    if (pathname !== '/') {
      document.location.href = '/'
    }
  } else {
    if (!AXIOS_INSTANCE.defaults.headers.common.Authorization) {
      AXIOS_INSTANCE.defaults.headers.common.Authorization = `Bearer ${session.id_token}`
    }
  }

  return (
    <div className="flex">
      <div className="overflow-x-hidden w-full">
        {isShowNavBar && <RootNavBar isOpen={!!isMenuOpen} setIsOpen={setIsMenuOpen} />}
        <div
          className={clsx('pr-16', 'transition-all', 'ease-in-out', 'duration-[250ms]', isMenuOpen ? 'pl-80' : 'pl-16')}
        >
          <div className="flex items-center justify-between">
            <ConsoleBreadcrumb />
            <Buttons>
              <RefreshMyInfoButton />
              <ModeToggle />
            </Buttons>
          </div>
          {children}
        </div>
      </div>
    </div>
  )
}
export default RootWrapper
